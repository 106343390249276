import React, { Component } from 'react';
import { Button, Loading } from 'sw-ui';
import PropTypes from 'prop-types';

import UploadForm from '../../../../components/UploadForm';
import ErrorDialog from '../../components/errorDialog';
import UploadTag from '../../components/uploadTag';
import ContactsTable from '../contactsTable';

import COMPONENTS from '../../../../bi/constants/components';
import {
  REQUIREDEXTENSIONS,
  UPDATETIMEOUTMS,
  UPLOADFORM,
  LABELS_TABELS,
} from '../../../../bi/constants/amo';

import styles from '../../styles/styles.module.css';

const { BUTTON: { THEME: { FLAT } } } = COMPONENTS;

export default class UploadContacts extends Component {
  static propTypes = { amoCRMService: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    const {
      uploaded,
      failed,
      error,
      processing,
      contacts,
      isLoading,
    } = props.amoCRMService.get();

    this.state = {
      uploaded,
      failed,
      error,
      processing,
      tag: '',
      isLoading,
      contacts,
    };
  }

  timerId = 0;

  componentDidMount() {
    const { amoCRMService } = this.props;
    this.unsubscribe = amoCRMService.subscribe(this.updateState);
    amoCRMService.getInfoContacts();
  }

  componentWillUnmount() {
    const { amoCRMService: { resetStore } } = this.props;

    if (this.unsubscribe) {
      resetStore();
      this.unsubscribe();
    }
  }

  updateState = ({
    uploaded,
    failed,
    error,
    processing,
    isLoading,
    contacts,
  }) => {
    this.setState({
      uploaded,
      failed,
      error,
      processing,
      isLoading,
      contacts,
    });
  };

  updateStatus = () => {
    const { amoCRMService: { getInfoContacts } } = this.props;
    getInfoContacts();
  };

  handleProcessing = () => {
    const { processing } = this.state;

    if (!processing) {
      clearTimeout(this.timerId);

      return;
    }

    this.updateStatus();
    this.timerId = setTimeout(this.handleProcessing, UPDATETIMEOUTMS);
  };

  onRemove = () => {
    const { amoCRMService: { resetStore, getInfoContacts } } = this.props;

    resetStore();
    getInfoContacts();
  };

  uploadAndProcessFile = async (file) => {
    const { amoCRMService: { uploadContacts } } = this.props;
    const { tag } = this.state;

    await uploadContacts(file, tag);
    this.handleProcessing();
  };

  resetError = () => {
    const { amoCRMService: { resetError } } = this.props;
    resetError();
  };

  renderUploadForm = () => {
    const { uploaded, processing } = this.state;

    return (
      <div className={ styles.upload_wrap }>
        <UploadForm
          modalFileUpload
          extensions={ REQUIREDEXTENSIONS }
          onFileAdded={ this.uploadAndProcessFile }
          onFileRemoved={ this.onRemove }
          uploaded={ uploaded && !processing }
          chooseFileLabel={ UPLOADFORM.CHOOSEFILE }
          description={ UPLOADFORM.DESCRIPTION }
          invalidExtension={ UPLOADFORM.INVALIDEXTENSION }
        />
      </div>
    );
  };

  renderUpdateButton = () => {
    const { amoCRMService: { getInfoContacts } } = this.props;

    return (
      <div className={ styles.buttonWrapper }>
        <Button
          theme={ FLAT }
          className={ styles.buttonUpdate }
          onClick={ () => getInfoContacts() }
        >
          { LABELS_TABELS.BUTTON_UPDATE }
        </Button>
      </div>
    );
  };

  renderLoading = () => (
    <div className={ styles.loading }>
      <Loading size='large' />
    </div>
  );

  renderContactsTable = () => {
    const { contacts, isLoading } = this.state;
    const { amoCRMService } = this.props;

    return isLoading
      ? this.renderLoading()
      : (
        <ContactsTable
          contacts={ contacts }
          amoCRMService={ amoCRMService }
        />
      );
  };

  renderErrorDialog = () => {
    const { failed, error } = this.state;

    if (!failed) {
      return null;
    }

    return (
      <ErrorDialog
        onClick={ this.resetError }
        error={ error }
      />
    );
  };

  changeTag = ({ target }) => this.setState({ tag: target.value });

  render() {
    const uploadFormHtml = this.renderUploadForm();
    const errDialogHtml = this.renderErrorDialog();
    const { tag } = this.state;

    return (
      <div className={ styles.wrap }>
        <UploadTag
          tag={ tag }
          onChange={ this.changeTag }
        />
        { uploadFormHtml }
        <div className={ styles.tabels }>
          { this.renderUpdateButton() }
          <div className={ `${styles.row} ${styles.header}` }>
            <div className={ styles.item }>{ LABELS_TABELS.ID }</div>
            <div className={ styles.item }>{ LABELS_TABELS.DATE }</div>
            <div className={ styles.item }>{ LABELS_TABELS.DONE }</div>
            <div className={ styles.item }>{ LABELS_TABELS.PROGRESS }</div>
            <div className={ styles.item } />
          </div>
          { this.renderContactsTable() }
        </div>
        { errDialogHtml }
      </div>
    );
  }
}
