import React, { Component } from 'react';
import PropTypes from 'prop-types';

import NoResult from '../../../../components/NoResult';
import UploadForm from '../../../../components/UploadForm';

import { REQUIREDEXTENSIONS, UPLOADFORM } from '../../../../bi/constants/amo';
import styles from '../../styles/amoReport.module.css';

const HINT = 'Загрузите отчет, скачивание начнется автоматически';

export default class AmoReportPage extends Component {
  static propTypes = { amoCRMService: PropTypes.object.isRequired };

  constructor(props) {
    super(props);

    const {
      uploaded,
      failed,
      error,
    } = props.amoCRMService.get();

    this.state = {
      uploaded,
      failed,
      error,
    };
  }

  componentDidMount() {
    const { amoCRMService } = this.props;
    this.unsubscribe = amoCRMService.subscribe(this.updateState);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  updateState = ({
    uploaded,
    failed,
    error,
  }) => {
    this.setState({
      uploaded,
      failed,
      error,
    });
  };

  handleOnFileAdded = async (file) => {
    await this.props.amoCRMService.processAmoReport(file);
  };

  renderErrorDialog = () => {
    const { failed, error } = this.state;

    return failed && (
      <NoResult
        onClose={ () => this.props.amoCRMService.resetError() }
        message={ error }
      />
    );
  };

  render() {
    return (
      <div className={ styles.main }>
        <div className={ styles.upload }>
          <div className={ styles.text }>
            { HINT }
          </div>
          <UploadForm
            extensions={ REQUIREDEXTENSIONS }
            onFileAdded={ this.handleOnFileAdded }
            uploaded={ this.state.uploaded }
            chooseFileLabel={ UPLOADFORM.CHOOSEFILE }
            description={ UPLOADFORM.DESCRIPTION }
            invalidExtension={ UPLOADFORM.INVALIDEXTENSION }
          />
        </div>
        { this.renderErrorDialog() }
      </div>
    );
  }
}
